<div class="app-bar">
  <div class="logo">
    <img src="/images/smd-logo.webp" />
  </div>
  @if (messages().length) {
    <button title="Ny" (click)="startNewConversation()">
      <img src="/images/chat_add.png" />
    </button>
  }
</div>

<div class="content">
  <div class="content-inner">
    @for (message of messages(); track $index) {
      <div class="message {{message.sender}}">
        <div [innerHTML]="message.content | marked">
        </div>
      </div>
    }
  </div>
</div>
<div class="footer">
  <div class="input">
    <input
      [disabled]="loading()"
      [placeholder]="loading() ? 'Loading...' : 'Indtast'"
      (keydown)="onKeyDown($event)">

    <img src="/images/chat_bubble.png" />
  </div>
</div>
<router-outlet />
