import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';

@Pipe({
  name: 'marked',
  standalone: true,
})
export class MarkedPipe implements PipeTransform {
  transform(value: string): string {
    return marked.parse(value.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/,"")) as string;
  }
}
