import { Component, effect, ElementRef, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ChatService } from './chat.service';
import { MarkedPipe } from './pipes/marked/marked.pipe';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MarkedPipe],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  private readonly chatService = inject(ChatService);
  private readonly el = inject(ElementRef);
  public readonly messages = this.chatService.messages;
  public readonly loading = this.chatService.loading;

  private readonly eff = effect(() => {
    const messages = this.messages(); // Do not remove this.
    this.scrollToBottom();
  })

  public onKeyDown(evt: KeyboardEvent) {
    const target = evt.target as HTMLInputElement;
    const value = target.value.trim();

    if(evt.key.toLowerCase() === 'enter') {
      target.value = '';
      if (value) {
        this.chatService.createNewMessage(value).subscribe(() => {
          // this.scrollToBottom();
        });
      }
    }
  }
  private scrollToBottom() {
    setTimeout(() => {
      const messagesContainer = (this.el.nativeElement as HTMLElement).getElementsByClassName('content-inner')[0] as HTMLDivElement;
      window.scroll(0, messagesContainer.scrollHeight);
    }, 0);
  }

  public startNewConversation() {
    this.chatService.startNewThread().subscribe();
  }
}
