import { Injectable, signal } from '@angular/core';
import OpenAI from 'openai';
import { catchError, forkJoin, from, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

const assistantId = 'asst_X7L0Z5pSzN0shR7xT0ItArEA';
const apiKey = 'sk-proj-ZeEYDwaZ7AkHH9dtXLP7T3BlbkFJRLQ5HD3EkghEhj7Ekp1t';
const openai = new OpenAI({
  apiKey: apiKey,
  dangerouslyAllowBrowser: true,
});
const localStorageKey = 'thread-id';

export interface Message {
  content: string;
  sender: 'user' | 'assistant';
}

@Injectable({
  providedIn: 'root',
})
export class ChatService {

  public readonly threadId = signal<string | undefined>(localStorage.getItem(localStorageKey) || undefined);
  public readonly messages = signal<Message[]>([]);
  public readonly loading = signal(false);

  public initializeThread() {
    return of(this.threadId()).pipe(
      switchMap(id => {
        if (id) {
          return this.threadExists(id).pipe(
            switchMap(exists => {
              if (!exists) {
                return this.createNewThread();
              }
              return of(id);
            })
          );
        }
        return this.createNewThread();
      }),
      tap((id) => {
        localStorage.setItem(localStorageKey, id)
        this.threadId.set(id);
      }),
      switchMap(id => {
        return this.getAllMessages(id)
      }),
      tap(messages => {
        this.messages.set(messages);
      }),
    )
  }

  public startNewThread() {
    return this.createNewThread().pipe(
      tap((id) => {
        localStorage.setItem(localStorageKey, id)
        this.threadId.set(id);
      }),
      switchMap(id => {
        return this.getAllMessages(id)
      }),
      tap(messages => {
        this.messages.set(messages);
      }),
    )
  }

  private createNewThread() {
    return from(openai.beta.threads.create()).pipe(
      map(t => t.id)
    );
  }

  private threadExists(threadId: string) : Observable<boolean> {
    return from(openai.beta.threads.retrieve(threadId)).pipe(
      map(t => !!t),
      catchError(() => {
        return of(false);
      })
    );
  }

  public createNewMessage(message: string) : Observable<Message> {
    const threadId = this.threadId();
    if (!threadId) {
      throw `Missing threadId`;
    }
    this.messages.set([
      ...this.messages(),
      {
        content: message,
        sender: 'user' as const,
      }
    ])
    this.loading.set(true);
    return from(openai.beta.threads.runs.createAndPoll(
      threadId,
      {
        assistant_id: assistantId,
        instructions: message
      }
    )).pipe(
      switchMap(run => {
        return this._getAllMessages(threadId).pipe(
          map(messages => {
            const foundMessage = messages.find(m => m.runId === run.id)
            if(!foundMessage) {
              return undefined;
            }
            return {
              content: foundMessage.content,
              sender: 'assistant' as const,
            } as Message;
          })
        )
      }),
      map((message) => {
        if (!message) {
          throw `Message not found`;
        }
        this.messages.set([
          ...this.messages(),
          message
        ])
        return message;
      }),
      tap((message) => {
        this.loading.set(false);
      }),
      catchError(err => {
        this.loading.set(false);
        return throwError(() => err);
      })
    );
  }

  private getAllMessages(threadId: string): Observable<Message[]> {
    return forkJoin([
      this._getAllMessages(threadId),
      this._getAllRuns(threadId),
    ]).pipe(
      map(([messages, runs]) => {
        return messages.reduce((acc, message) => {
          const run = runs.find(r => r.id === message.runId);
          if (!run) {
            console.error(`Message without run`);
            return acc;
          }
          return [
            {
              content: run.content,
              sender: 'user' as const,
            },
            {
              content: message.content,
              sender: 'assistant' as const,
            },
            ...acc,
          ];
        }, [] as Message[]);
      })
    )
  }

  private _getAllRuns(threadId: string): Observable<{
    id: string;
    content: string;
  }[]> {
    return from(
      openai.beta.threads.runs.list(
        threadId
      )).pipe(
      map((runs) => {
        if('data' in runs) {
          return runs.data.map(run => {
            return {
              content:  run.instructions,
              id: run.id,
            };
          });
        }
        return [];
      })
    )
  }

  private _getAllMessages(threadId: string): Observable<{
    runId: string;
    content: string;
  }[]> {
    return from(
      openai.beta.threads.messages.list(
        threadId
      )).pipe(
      map((messages) => {
        const m : {
          runId: string;
          content: string;
        }[] = [];
        if('data' in messages) {
          messages.data.forEach(item => {
            const runId = item.run_id;

            if(!runId) {
              throw `Empty runId`;
            }
            m.push({
              content:  item.content.map(c => {
                if (c.type === 'text') {
                  return c.text.value;
                }
                if(c.type === 'image_file') {
                  return c.image_file.file_id
                }
                return c.image_url.url;
              }).join(''),
              runId,
            })
          });
        }
        return m;
      })
    )
  }
}
