import { ChatCompletionStream } from "./ChatCompletionStream.mjs";
export class ChatCompletionStreamingRunner extends ChatCompletionStream {
  static fromReadableStream(stream) {
    const runner = new ChatCompletionStreamingRunner();
    runner._run(() => runner._fromReadableStream(stream));
    return runner;
  }
  /** @deprecated - please use `runTools` instead. */
  static runFunctions(completions, params, options) {
    const runner = new ChatCompletionStreamingRunner();
    const opts = {
      ...options,
      headers: {
        ...options?.headers,
        'X-Stainless-Helper-Method': 'runFunctions'
      }
    };
    runner._run(() => runner._runFunctions(completions, params, opts));
    return runner;
  }
  static runTools(completions, params, options) {
    const runner = new ChatCompletionStreamingRunner();
    const opts = {
      ...options,
      headers: {
        ...options?.headers,
        'X-Stainless-Helper-Method': 'runTools'
      }
    };
    runner._run(() => runner._runTools(completions, params, opts));
    return runner;
  }
}
